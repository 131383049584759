<template>
  <div class="digital-certificate">
    <app-header icon="mobile-alt" title="Aplicativo"></app-header>

    <section>
      <div class="card p-4">
        <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
        <div class="card-header">
          <div class="card-header-icon">
            <b-icon icon="info-circle"></b-icon>
          </div>
          <div class="card-header-title">
            Lista de Configurações do Aplicativo
          </div>
          <div class="card-header-buttons">
            <b-tooltip label="Nova informação">
              <b-button
                @click="onNewAppSettingClick()"
                type="is-primary"
                size="is-small"
                icon-left="plus"
              >
                Adicionar
              </b-button>
            </b-tooltip>
          </div>
        </div>
        <div class="card-content">
          <b-table
            :data="data"
            :total="total"
            :per-page="perPage"
            :loading="isLoading"
            @page-change="onPageChange"
            paginated
            backend-pagination
            aria-page-label="Página"
            aria-next-label="Próxima Página"
            aria-current-label="Página Atual"
            aria-previous-label="Página Anterior"
          >
            <b-table-column v-slot="props" v label="Doutor">
              {{ props.row.doctor_name }}
            </b-table-column>

            <b-table-column v-slot="props" label="Cor Primária">
              <div
                v-bind:style="{
                  'background-color': props.row.primary_color,
                  color: '#000',
                  paddingLeft: '10px',
                }"
              >
                {{ props.row.primary_color }}
              </div>
            </b-table-column>

            <b-table-column v-slot="props" label="Cor Secundária">
              <div
                v-bind:style="{
                  'background-color': props.row.secondary_color,
                  color: '#000',
                  paddingLeft: '10px',
                }"
              >
                {{ props.row.secondary_color }}
              </div>
            </b-table-column>

            <b-table-column v-slot="props" label="Opções">
              <b-tooltip label="Editar" class="m-1">
                <b-button
                  @click="
                    onEditClick(
                      props.row.id
                    )
                  "
                  type="is-secondary"
                  size="is-small"
                  icon-right="pencil-alt"
                />
              </b-tooltip>
              <b-tooltip label="Deletar" class="m-1">
                <b-button
                  @click="onDeleteClick(props.row.id)"
                  type="is-danger"
                  size="is-small"
                  icon-right="trash"
                />
              </b-tooltip>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhuma informação encontrada.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import appSettingsService from '../../services/app-setting.service';

import NewAppSetting from '@/modals/NewAppSetting.vue';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    perPage: 10,
    doctors: [],
  }),
  computed: {
    ...mapGetters(['doctorId']),
  },
  methods: {
    onPageChange(page) {
      this.page = page;
    },
    onDeleteClick(settingId) {
      this.$buefy.dialog.confirm({
        message: 'Deseja realmente deletar essa configuração?',
        onConfirm: () => this.deleteSetting(settingId),
      });
    },
    deleteSetting(id) {
      appSettingsService
        .delete(id)
        .then(() => {
          this.$buefy.snackbar.open('Configuração deletada com sucesso!');
          this.loadAppSettings();
        })
        .catch(() =>
          this.$buefy.snackbar.open('Um erro inesperado aconteceu!')
        );
    },
    //loadDoctors() {
    //  this.isLoading = true;
    //  appSettingsService
    //    .getHasApp()
    //    .then(({ data }) => (this.doctors = data))
    //    .finally(() => (this.isLoading = false));
    //},
    loadAppSettings() {
      this.isLoading = true;
      appSettingsService
        .getIndex({ page: this.page })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;

          //console.log(total, items, current_page);

          this.total = total;
          this.page = current_page;
          this.data = items;
        })
        .catch(() => {
          this.$buefy.snackbar.open('Um erro inesperado aconteceu!');
        })
        .finally(() => (this.isLoading = false));
    },
    onEditClick(settingId) {
      this.$buefy.modal.open({
        parent: this,
        component: NewAppSetting,
        hasModalCard: true,
        trapFocus: true,
        props: {
          settingId: settingId,
        },
        events: {
          close: (data) => data && this.loadAppSettings(),
        },
      });
    },
    onNewAppSettingClick() {
      this.$buefy.modal.open({
        parent: this,
        component: NewAppSetting,
        hasModalCard: true,
        trapFocus: true,
        props: {
          doctorId: this.doctorId,
        },
        events: {
          close: (data) => data && this.loadAppSettings(),
        },
      });
    },
  },
  mounted() {
    this.loadAppSettings();
    //this.loadDoctors();
  },
};
</script>
