<template>
  <div class="app-setting-modal">
    <b-loading :active.sync="isLoading" :is-full-page="false" />
    <div class="modal-card">
      <header class="modal-card-head p-2">
        <p class="modal-card-title">
          {{ isNew ? 'Adicionar nova ' : 'Editar ' }} configuração do aplicativo
        </p>
      </header>
      <section class="modal-card-body">
        <div class="row mb-4">
          <div class="col-12">
            <b-field label="Médico(a)">
              <b-dropdown
                v-model="setting.doctor_id"
                scrollable
                max-height="400"
                expanded
              >
                <template #trigger>
                  <b-button icon-right="chevron-down" expanded>
                    {{ selectedDoctor }}
                  </b-button>
                </template>
                <b-dropdown-item custom aria-role="listitem">
                  <b-input
                    v-model="searchDoctor"
                    placeholder="Pesquise"
                    expanded
                  />
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="(doctor, idx) in filteredDoctor"
                  :value="doctor.id"
                  :key="idx"
                  aria-role="listitem"
                >
                  {{ doctor.name }}
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
            <small v-bind:style="{ color: errorColor }">
              {{ errors.doctor || errors.doctor_id}}
            </small>
          </div>
        </div>
        <div class="row mb-4" v-if="isNew">
          <div class="col-6">
            <app-input
              v-model="setting.password"
              :errors="errors.password"
              type="password"
              required
              minlength="6"
              autocomplete="new-password"
              name="password"
              label="Senha"
            ></app-input>
          </div>
          <div class="col-6">
            <app-input
              v-model="setting.password_confirmation"
              :errors="errors.password_confirmation"
              type="password"
              required
              minlength="6"
              autocomplete="new-password"
              name="password_confirmation"
              label="Confirme a senha"
            ></app-input>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-6">
            <app-input
              is-required
              type="color"
              label="Cor Primária"
              v-model="setting.primary_color"
              :errors="errors.primary_color"
            ></app-input>
          </div>
          <div class="col-6">
            <app-input
              is-required
              type="color"
              label="Cor Secundária"
              v-model="setting.secondary_color"
              :errors="errors.secondary_color"
            ></app-input>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 mb-4">
            <app-file-picker
              required
              label="Logo"
              v-model="setting.logo"
              accept="image/*"
              @input="onImageSelected"
              :errors="errors.logo"
              @fileDeleted="() => (setting.logo = null)"
            >
            </app-file-picker>
            <small v-bind:style="{ color: errorColor }">
              {{ errors.logo }}
            </small>
          </div>
        </div>
        <div v-if="setting.logo" class="row mb-4">
          <div class="col-12">
            <div class="logo">
              <img :src="getLogo" alt="Logo" />
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close', false)">Cancelar</b-button>
        <b-button @click="onSubmit" type="is-primary">Salvar</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import appSettingsService from '../services/app-setting.service';

export default {
  props: {
    settingId: Number,
  },
  computed: {
    isNew() {
      return !this.settingId;
    },
    filteredDoctor() {
      return this.doctors.filter(
        (doctor) =>
          doctor.name
            .toLowerCase()
            .indexOf(this.searchDoctor.toLowerCase()) >= 0
      );
    },
    selectedDoctor: function () {
      let selectedDoctor = this.doctors.find(
        (doctor) => doctor.id == this.setting.doctor_id
      );

      if (selectedDoctor == null) {
        return 'Selecione o(a) médico(a)';
      }

      return selectedDoctor.name;
    },
    getLogo() {
      const url =
        'https://s3.sa-east-1.amazonaws.com/static.bestdoctorsapp.com.br/';

      return this.setting.logo.includes('base64')
        ? this.setting.logo
        : `${url}${this.setting.logo}`;
    },
  },
  data: () => ({
    setting: {
      logo: '',
      doctor_id: null,
      primary_color: '',
      secondary_color: '',
    },
    errors: {},
    doctors: [],
    isLoading: false,
    searchDoctor: '',
    errorColor: 'red'
  }),
  methods: {
    onImageSelected() {},
    onSubmit() {
      this.isLoading = true;
      this.settingId && this.update();
      !this.settingId && this.save();
    },
    save() {
      const data = { ...this.setting };
      appSettingsService
        .store(data)
        .then(() => {
          this.$buefy.snackbar.open('Configuração cadastrada com sucesso!');
          this.$emit('close', true);
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status == 400) {
            this.$buefy.snackbar.open(
              'Verificar campos não informados ou inválidos!'
            );
            this.errors = response.data.message;
          } else {
            this.$buefy.snackbar.open('Erro ao cadastrar Configuração');
          }
        })
        .finally(() => (this.isLoading = false));
    },
    update() {
      const data = { ...this.setting };
      appSettingsService
        .update(this.settingId, data)
        .then(() => {
          this.$buefy.snackbar.open('Configuração atualizada com sucesso!');
          this.$emit('close', true);
        })
        .catch(({ response }) => {
          if (response.status === 422 || response.status == 400) {
            this.$buefy.snackbar.open(
              'Verificar campos não informados ou inválidos!'
            );
            this.errors = response.data.message;
          } else {
            this.$buefy.snackbar.open('Erro ao atualizar Configuração');
          }
        })
        .finally(() => (this.isLoading = false));
    },
    loadDoctors() {
      return appSettingsService.getDoctors().then(({ data }) => {
        return data;
      });
    },
    loadSetting(id) {
      return appSettingsService.getId(id).then(({ data }) => {
        return data;
      });
    },
  },
  mounted() {
    this.isLoading = true;
    const promises = [this.loadDoctors()];

    !this.isNew && promises.push(this.loadSetting(this.settingId));

    Promise.all(promises)
      .then((data) => {
        const [doctors, setting] = data;
        this.doctors = doctors;
        this.setting = { ...setting };
      })
      .finally(() => (this.isLoading = false));
  },
};
</script>

<style lang="scss" scoped></style>
