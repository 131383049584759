import Api from './api.service';

const ENDPOINT = 'doctor-app-setting';

export default {
  verifyDoctor: (doctorId, params = {}) =>
    Api.get(`${ENDPOINT}/verify-has-app/${doctorId}`, { params }),
  getIndex: (params = {}) => Api.get(`${ENDPOINT}/index`, { params }),
  getDoctors: (params = {}) => Api.get(`${ENDPOINT}/get-doctors`, { params }),
  getId: (id) => Api.get(`${ENDPOINT}/show/${id}`),
  store: (data) => Api.post(`${ENDPOINT}/store`, data),
  update: (id, data) => Api.put(`${ENDPOINT}/update/${id}`, data),
  delete: (id) => Api.delete(`${ENDPOINT}/delete/${id}`),
  info: (data) => Api.post(`${ENDPOINT}/info`, data),
};