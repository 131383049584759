var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"digital-certificate"},[_c('app-header',{attrs:{"icon":"mobile-alt","title":"Aplicativo"}}),_c('section',[_c('div',{staticClass:"card p-4"},[_c('b-loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":"info-circle"}})],1),_c('div',{staticClass:"card-header-title"},[_vm._v(" Lista de Configurações do Aplicativo ")]),_c('div',{staticClass:"card-header-buttons"},[_c('b-tooltip',{attrs:{"label":"Nova informação"}},[_c('b-button',{attrs:{"type":"is-primary","size":"is-small","icon-left":"plus"},on:{"click":function($event){return _vm.onNewAppSettingClick()}}},[_vm._v(" Adicionar ")])],1)],1)]),_c('div',{staticClass:"card-content"},[_c('b-table',{attrs:{"data":_vm.data,"total":_vm.total,"per-page":_vm.perPage,"loading":_vm.isLoading,"paginated":"","backend-pagination":"","aria-page-label":"Página","aria-next-label":"Próxima Página","aria-current-label":"Página Atual","aria-previous-label":"Página Anterior"},on:{"page-change":_vm.onPageChange}},[_c('b-table-column',{attrs:{"v":"","label":"Doutor"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.doctor_name)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Cor Primária"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{style:({
                'background-color': props.row.primary_color,
                color: '#000',
                paddingLeft: '10px',
              })},[_vm._v(" "+_vm._s(props.row.primary_color)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Cor Secundária"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{style:({
                'background-color': props.row.secondary_color,
                color: '#000',
                paddingLeft: '10px',
              })},[_vm._v(" "+_vm._s(props.row.secondary_color)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Opções"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tooltip',{staticClass:"m-1",attrs:{"label":"Editar"}},[_c('b-button',{attrs:{"type":"is-secondary","size":"is-small","icon-right":"pencil-alt"},on:{"click":function($event){return _vm.onEditClick(
                    props.row.id
                  )}}})],1),_c('b-tooltip',{staticClass:"m-1",attrs:{"label":"Deletar"}},[_c('b-button',{attrs:{"type":"is-danger","size":"is-small","icon-right":"trash"},on:{"click":function($event){return _vm.onDeleteClick(props.row.id)}}})],1)]}}])}),_c('template',{slot:"empty"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"frown","size":"is-large"}})],1),_c('p',[_vm._v("Nenhuma informação encontrada.")])])])])],2)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }